import React, { useEffect } from "react";
import './GetStarted.css';

const GetStarted = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="get-started-page">
      <h1>For Clients</h1>
      <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/appiV4R1Jhs0s5vMe/shr6qQdUMiv1cmZQo"
        style={{ background: 'transparent', border: '0px' }}
        title="Airtable Embed"
      ></iframe>
    </div>
  );
};

export default GetStarted;