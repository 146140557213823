import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
  return (
    <div className="coming-soon">
      <h1>Coming Soon!</h1>
    </div>
  );
};

export default Testimonials;